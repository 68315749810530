import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";
import HelpIcon from "@material-ui/icons/Help";

import useStyles from "./styles";

const DialogCancelAnalysis = ({ open, handleCancel, handleConfirm }) => {
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (open) {
      setIsLoading(false);
    }
  }, [open]);

  const handleDelete = async () => {
    setIsLoading(true);
    await handleConfirm();
    setIsLoading(false);
  };

  return (
    <Dialog
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleCancel();
        }
      }}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      {isLoading ? (
        <Box className={classes.loadingContainer}>
          <LoadingStateHorizontal isVisible={isLoading} />
        </Box>
      ) : (
        <>
          <MuiDialogTitle disableTypography className={classes.root}>
            <Box className={classes.titleContainerAnalysis}>
              <HelpIcon fontSize="large" style={{ color: "#9A1010" }} />
              <Typography variant="h6" className={classes.dialogTitle}>
                Cancel Insights Report
              </Typography>
            </Box>
            {handleCancel ? (
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={handleCancel}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </MuiDialogTitle>
          <MuiDialogContent>
            <Box className={classes.filterGroupCenterCancel}>
              <Typography
                variant="body1"
                className={classes.filterTitleAnalysisSubTitle}
              >
                Are you sure you want to close this review?
              </Typography>
              <Typography
                variant="body1"
                className={classes.filterTitleAnalysis}
              >
                By closing this page you will loose all the responses, consider
                minimizing it instead or saving the analysis first.
              </Typography>
            </Box>
          </MuiDialogContent>
          <MuiDialogActions>
            <>
              <Button
                onClick={handleCancel}
                className={classes.buttonCancelVariantAnalysis}
                variant="outlined"
                size="large"
                color="primary"
                fullWidth
              >
                Stay
              </Button>

              <Button
                onClick={handleDelete}
                className={classes.buttonConfirmVariantAnalysis}
                color="primary"
                variant="contained"
                size="large"
                fullWidth
              >
                Close Insights
              </Button>
            </>
          </MuiDialogActions>
        </>
      )}
    </Dialog>
  );
};

export default DialogCancelAnalysis;
