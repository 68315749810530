import React, { useEffect, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import useStyles from "./styles";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Box from "@material-ui/core/Box";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CategorySelectionTagList from "components/common/LunaLens/categorySelectionTagList";

export const FormCategoryInput = ({
  value,
  validateOnType,
  onKeyPress,
  readOnly,
  endAdornment,
  onChange,
  name,
  styleOverride,
  gridSizes,
  placeholder,
  internal,
  label,
  categories,
  errorMessage,
  onHandleSubmit,
  disableButton,
}) => {
  const classes = useStyles();

  const [stateValue, setStateValue] = useState("");
  const [gridSizeMd, setGridSizeMd] = useState(8);
  const [gridSizeXs] = useState(12);

  useEffect(() => {
    if (value !== undefined) {
      setStateValue(value);
    }
  }, [value]);

  const handleChange = (event) => {
    setStateValue(event.target.value);
    if (validateOnType) {
      validateOnType(event.target);
    }
  };

  useEffect(() => {
    if (gridSizes) {
      gridSizes.map((sizes) => {
        switch (sizes.size) {
          case "md":
            return setGridSizeMd(sizes.val);
          default:
            return 12;
        }
      });
    }
  }, [gridSizes]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12} className={classes.labelContainer}>
        <InputLabel
          htmlFor={name}
          className={internal ? classes.inputInternalLabel : classes.inputLabel}
        >
          {label}
        </InputLabel>
      </Grid>
      <Grid item xs={gridSizeXs} md={gridSizeMd}>
        <Box
          style={
            errorMessage
              ? {
                  border: errorMessage
                    ? "1px solid #9A1010"
                    : "1px solid #ECECEC",
                  borderRadius: "4px",
                  padding: "0px 10px",
                }
              : {
                  border: errorMessage
                    ? "1px solid #9A1010"
                    : "1px solid #ECECEC",
                  borderRadius: "4px",
                  padding: "0px 10px",
                  ...styleOverride,
                }
          }
        >
          {categories && categories.length > 0 && (
            <Box style={{ marginTop: "11px" }}>
              <CategorySelectionTagList categories={categories} />
            </Box>
          )}
          <Box className={classes.containerAddButton}>
            <Input
              name={name}
              id={name}
              value={stateValue}
              multiline={true}
              onChange={onChange ?? handleChange}
              variant="standard"
              placeholder={placeholder}
              onKeyDown={onKeyPress}
              disabled={readOnly ? readOnly : false}
              disableUnderline
              className={classes.commentInputText}
            />
            <IconButton
              size="small"
              style={{
                color: stateValue.trim() !== "" ? "#4F98BC" : "#D9D9D9",
              }}
              onClick={onHandleSubmit}
              disabled={disableButton}
            >
              {endAdornment}
            </IconButton>
          </Box>
        </Box>
        {errorMessage && (
          <Box
            style={{
              ...styleOverride,
              marginTop: 8,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 4,
            }}
          >
            <ErrorOutlineIcon style={{ fontSize: 20, color: "#9A1010" }} />
            <InputLabel htmlFor={name} className={classes.inputLabelError}>
              {errorMessage}
            </InputLabel>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default FormCategoryInput;
