import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import PageHeaderTabs from "./pageHeaderTabs";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import useStyles from "./styles";

import {
  usePageHeaderState,
  usePageHeaderDispatch,
} from "contexts/pageHeaderContext";

const PageHeader = (props) => {
  const classes = useStyles();
  const dispatchPageHeader = usePageHeaderDispatch();
  const { pageHeaderTabs } = usePageHeaderState();

  useEffect(() => {
    const currentPage = window.location.pathname;
    const findTab = pageHeaderTabs.find((page) => page.link === currentPage);

    if (findTab) {
      dispatchPageHeader({
        type: "SET_CURRENT_TAB",
        currentTab: findTab.id,
      });
    } else if (
      currentPage.startsWith("/lens-analysis/") ||
      currentPage.startsWith("/project/") ||
      currentPage.startsWith("/quote/") ||
      currentPage.startsWith("/workticket/") ||
      currentPage.startsWith("/dashboard/") ||
      currentPage.startsWith("/open-position/") ||
      currentPage.startsWith("/dashboard-kpi/") ||
      (currentPage.startsWith("/team/") &&
        !currentPage.startsWith("/team/org-chart"))
    ) {
      const tabId = new Date().valueOf();
      pageHeaderTabs.push({
        id: tabId,
        label: props.pageTitle,
        link: window.location.pathname,
        hasClose: true,
        parent: false,
      });
      dispatchPageHeader({
        type: "SET_PAGE_TAB",
        pageHeaderTabs: [...pageHeaderTabs],
      });
      dispatchPageHeader({ type: "SET_CURRENT_TAB", currentTab: tabId });
    } else {
      const tabId = new Date().valueOf();
      const pageHeaderTabParent = {
        id: tabId,
        label: props.pageTitle,
        link: window.location.pathname,
        hasClose: true,
        parent: true,
      };
      const pageHeaderTabChild = pageHeaderTabs.filter((tab) => !tab.parent);
      dispatchPageHeader({
        type: "SET_PAGE_TAB",
        pageHeaderTabs: [pageHeaderTabParent, ...pageHeaderTabChild],
      });
      dispatchPageHeader({ type: "SET_CURRENT_TAB", currentTab: tabId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchPageHeader, props.pageTitle]);

  return (
    <Box className={classes.header}>
      <PageHeaderTabs />
      <Paper elevation={0} className={classes.breadcrumbs}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {props.children && props.children.length ? (
            props.children.map((child, index) => (
              <Typography key={index} className={classes.breadcrumbLink}>
                {child}
              </Typography>
            ))
          ) : (
            <Typography className={classes.breadcrumbLink}>
              {props.children}
            </Typography>
          )}
          <Link className={classes.breadcrumbActive}>{props.pageTitle}</Link>
        </Breadcrumbs>
      </Paper>
    </Box>
  );
};

export default PageHeader;
