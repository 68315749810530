import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  addNewPartnerComment,
  deletePartnerComment,
  getPartnerComments,
  updatePartnerComment,
} from "../../../services/partners/commentsService";
import React, { useEffect, useState } from "react";
import { useCommentDialogue } from "../context/CommentDialogueContext";
import { useGetUserId } from "../context/UserIdContext";

export function useComments() {
  const { userId } = useGetUserId();
  const queryClient = useQueryClient();
  const [commentAction, dispatchCommentAction] = useCommentDialogue();
  const [comment, setComment] = useState("");
  const { isOpenAddDialog, isEditing, commentToEdit } = commentAction;
  const [searched, setSearched] = useState("");
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const { data: commentsList, isLoading: commentsLoading } = useQuery(
    ["partnerComments", userId],
    async () => {
      const { data } = await getPartnerComments(userId);
      return data.data;
    },
    {
      enabled: !!userId,
    }
  );
  const dispatchEditComment = (comment) => {
    dispatchCommentAction({
      type: "TOGGLE_EDIT_COMMENT",
      comment,
      open: true,
      isEditing: true,
    });
  };
  const dispatchCloseDialog = () => {
    dispatchCommentAction({
      type: "TOGGLE_EDIT_COMMENT",
      open: false,
      isEditing: false,
      comment: null,
    });
  };
  useEffect(() => {
    if (commentToEdit) {
      setComment(commentToEdit.body);
    }
  }, [commentToEdit]);
  const addPartnerComment = useMutation(addNewPartnerComment, {
    onSuccess: () => {
      queryClient.invalidateQueries("partnerComments");
      setLoadingState(false);
    },
    onError: () => {
      setLoadingState(false);
    },
  });
  const deleteComment = useMutation(deletePartnerComment, {
    onSuccess: () => {
      queryClient.invalidateQueries("partnerComments");
      setLoadingState(false);
    },
    onError: () => {
      setLoadingState(false);
    },
  });
  const updateComment = useMutation(updatePartnerComment, {
    onSuccess: () => {
      queryClient.invalidateQueries("partnerComments");
      setLoadingState(false);
      dispatchCloseDialog();
    },
    onError: () => {
      setLoadingState(false);
    },
  });
  const handleChange = (event) => {
    setSearched(event.target.value);
  };
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const [value, setValue] = React.useState(0);
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmitComment();
    }
  };
  const handleSubmitComment = () => {
    if (comment.trim() === "") {
      return;
    }
    let tab = "";
    switch (value) {
      case 0:
        tab = "internal";
        break;
      case 1:
        tab = "partner";
        break;
      default:
    }
    setLoadingState(true);
    if (isEditing) {
      const data = {
        data: {
          body: comment,
          tab,
        },
        id: commentToEdit.id,
        partner_id: userId,
      };
      updateComment.mutate(data);
    } else {
      addPartnerComment.mutate({
        partner_id: userId,
        data: {
          body: searched,
          tab,
        },
      });
      setSearched("");
    }
  };
  return {
    commentsList,
    commentsLoading,
    addPartnerComment,
    loadingState,
    handleKeyDown,
    handleChange,
    handleSubmitComment,
    handleChangeTab,
    value,
    setValue,
    searched,
    deleteComment,
    isLoadingDialog,
    setIsLoadingDialog,
    isOpenAddDialog,
    isEditing,
    commentToEdit,
    dispatchEditComment,
    dispatchCloseDialog,
    comment,
    setComment,
    userId,
  };
}
