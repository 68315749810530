import React from "react";
import { withRouter, useLocation } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import UserIcon from "@material-ui/icons/SupervisedUserCircle";
import RoleIcon from "@material-ui/icons/Security";
import CustomerIcon from "@material-ui/icons/PermContactCalendar";
import FormsIcon from "@material-ui/icons/ListAlt";
import JobIcon from "@material-ui/icons/Work";
import {
  HomeDark,
  ProjectDark,
  QualityDark,
  TeamDark,
  FinancialDark,
  RequestDark,
  QuoteDark,
  WorkticketDark,
  AssetDark,
  KpiDark,
  NoteDark,
  AnalysisReportDark,
  PartnerIcon,
} from "../Icons/navIconsV2";
import { truncateString } from "../../util/stringFormat";
import { default as useStyles, StyledTabs, StyledTab } from "./stylesTabs";
import SchoolIcon from "@material-ui/icons/School";

import {
  usePageHeaderState,
  usePageHeaderDispatch,
} from "contexts/pageHeaderContext";
import { AnalysisReportActive } from "components/common/LunaLens/images/images";

const icon = (icon, currentUrl) => {
  const baseUrl = icon;
  icon = icon.toLowerCase();
  if (icon.startsWith("/request")) {
    return <RequestDark />;
  }
  if (icon.startsWith("/project")) {
    return <ProjectDark />;
  }
  if (icon.startsWith("/workticket")) {
    return <WorkticketDark />;
  }
  if (icon.startsWith("/user")) {
    return <UserIcon fontSize="small" />;
  }
  if (icon.startsWith("/role")) {
    return <RoleIcon fontSize="small" />;
  }
  if (icon.startsWith("/customer")) {
    return <CustomerIcon fontSize="small" />;
  }
  if (icon.startsWith("/job")) {
    return <JobIcon fontSize="small" />;
  }
  if (icon.startsWith("/timekeeping")) {
    return <FinancialDark />;
  }
  if (icon.startsWith("/financial")) {
    return <FinancialDark />;
  }
  if (icon.startsWith("/asset")) {
    return <AssetDark />;
  }
  if (icon.startsWith("/quote")) {
    return <QuoteDark />;
  }
  if (icon.startsWith("/notes")) {
    return <NoteDark />;
  }
  if (icon.startsWith("/dashboard-kpi")) {
    return <KpiDark />;
  }
  if (icon.startsWith("/dashboard")) {
    return <HomeDark />;
  }
  if (icon.startsWith("/team")) {
    return <TeamDark />;
  }
  if (icon.startsWith("/evaluations")) {
    return <TeamDark />;
  }
  if (icon.startsWith("/open-position")) {
    return <TeamDark />;
  }
  if (icon.startsWith("/surveys")) {
    return <QualityDark />;
  }
  if (icon.startsWith("/forms")) {
    return <FormsIcon />;
  }
  if (icon.startsWith("/learn")) {
    return <SchoolIcon />;
  }
  if (icon.startsWith("/partner")) {
    return <PartnerIcon />;
  }

  if (currentUrl === baseUrl) {
    return <AnalysisReportActive width={23} height={24} />;
  }
  if (icon.startsWith("/lens")) {
    return <AnalysisReportDark width={23} height={24} />;
  }
};

const TableTabs = (props) => {
  const location = useLocation();
  const currentUrl = location.pathname;
  const classes = useStyles();
  const { pageHeaderTabs, currentTab } = usePageHeaderState();
  const dispatchPageHeader = usePageHeaderDispatch();

  const handleTabChange = (event, newTab) => {
    const findPage = pageHeaderTabs.find((page) => page.id === newTab);
    if (findPage) {
      const { history } = props;
      history.push(findPage.link);
    }
    dispatchPageHeader({ type: "SET_CURRENT_TAB", currentTab: newTab });
  };
  const handleClose = (e, id) => {
    const { history } = props;
    e.stopPropagation();
    const removeTabPage = pageHeaderTabs.filter((page) => page.id !== id);
    if (currentTab === id) {
      history.push(removeTabPage[0].link);
    }

    dispatchPageHeader({
      type: "SET_PAGE_TAB",
      pageHeaderTabs: [...removeTabPage],
    });
  };

  if (!pageHeaderTabs.find((page) => page.id === currentTab)) {
    return null;
  }

  const charLabel = pageHeaderTabs.length > 5 ? 5 : 16;

  return (
    <Box>
      <Box
        position="static"
        color="default"
        className={
          pageHeaderTabs.length > 5
            ? classes.tabBarAppBarCompact
            : classes.tabBarAppBar
        }
      >
        <StyledTabs
          value={currentTab}
          onChange={handleTabChange}
          textColor="secondary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs"
          className={classes.root}
        >
          {pageHeaderTabs.map((item) => (
            <StyledTab
              key={item.id}
              label={
                <Box>
                  <Tooltip
                    title={item.label.length > charLabel ? item.label : ""}
                  >
                    <Box>{truncateString(`${item.label}`, charLabel)}</Box>
                  </Tooltip>
                  {pageHeaderTabs.length > 1 ? (
                    <CloseIcon
                      className={classes.closeButton}
                      size="small"
                      onClick={(e) => handleClose(e, item.id)}
                      fontSize="inherit"
                    />
                  ) : null}
                </Box>
              }
              value={item.id}
              icon={icon(item.link, currentUrl)}
            />
          ))}
        </StyledTabs>
      </Box>
    </Box>
  );
};

export default withRouter(TableTabs);
