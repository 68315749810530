import React, { useState, useEffect } from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import lunaAnimatedSpinner from "../../../assets/icons/loadingLunaSearch.gif";
import lunaSolidPlaceholder from "../../../assets/icons/solidLunaSearch.jpg";
import { useFilterState } from "contexts/filterContext";
import classnames from "classnames";
import useStyles from "./styles";

const SearchBar = (props) => {
  const [stateValue, setStateValue] = useState("");
  const { searchString } = useFilterState();
  const classes = useStyles();
  const {
    handleSearch,
    placeholder,
    controlledSearch,
    isSearchAi,
    handleSearchClear,
    isLoadingSearch,
    module,
  } = props;

  useEffect(() => {
    if (searchString !== "") {
      setStateValue(searchString);
    } else {
      setStateValue("");
    }
  }, [searchString]);

  const handleChange = (event) => {
    setStateValue(event.target.value);
    if (event.target.value === "") {
      handleSearchClear();
    }
  };

  const handleClear = () => {
    setStateValue("");
    handleSearchClear();
  };

  const getStartAdornment = () => {
    if (isSearchAi) {
      return (
        <InputAdornment position="start">
          <img
            src={isLoadingSearch ? lunaAnimatedSpinner : lunaSolidPlaceholder}
            width="40"
            height="40"
            alt="Loading"
          />
        </InputAdornment>
      );
    }
    return null;
  };

  const getEndAdornment = () => {
    return (
      <InputAdornment position="end">
        {searchString !== "" || (controlledSearch && stateValue !== "") ? (
          <IconButton
            aria-label="clear"
            className={classes.clearSearchButton}
            onClick={handleClear}
          >
            <CloseIcon />
          </IconButton>
        ) : (
          <SearchIcon
            className={classes.searchIcon}
            onClick={(ev) => handleSearch(ev, stateValue)}
          />
        )}
      </InputAdornment>
    );
  };

  return (
    <OutlinedInput
      name="searchField"
      onKeyPress={(ev) => handleSearch(ev, stateValue)}
      value={stateValue}
      onChange={handleChange}
      inputProps={{ autoComplete: "off" }}
      placeholder={placeholder ? placeholder : undefined}
      startAdornment={getStartAdornment()}
      endAdornment={getEndAdornment()}
      disabled={isSearchAi && isLoadingSearch}
      className={classnames(classes.inputField, {
        [classes.inputFieldSearchAi]: isSearchAi && module === "partners",
        [classes.inputFieldWorkticketSearchAi]:
          isSearchAi && module === "worktickets",
      })}
    />
  );
};

export default SearchBar;
