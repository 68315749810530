import React, { useState, useEffect, useContext } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import FormHelperText from "@material-ui/core/FormHelperText";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import ErrorDialog from "components/ui/dialog/errorDialog";
import FilterModal from "./dialog/partnerFiltersDialog";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import RedirectDialog from "./dialog/redirectDialog";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import GlobalUiContext from "contexts/globalUiContext";
import { logException } from "components/util/logUtil";
import {
  mapData,
  fetchAndSetData,
  filterCitiesBySelectedStates,
  filterZipCodesBySelectedCities,
} from "components/util/filterGeolocationUtils";
import { partnerStatus } from "constants.js";
import { ownershipTypes } from "constants.js";
import {
  getTrades,
  getStates,
  getCities,
  getZipCodes,
  createPartner,
  createCity,
  createZipCode,
} from "services/partnerService";
import Joi from "joi-browser";
import * as classnames from "classnames";
import useStyles from "./styles";
import { mapBoxKey } from "lib/config";
import { SearchBox } from "@mapbox/search-js-react";

const phoneNumberRegex = /^(\+1\s?)?(\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/;
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const websiteRegex = /^(https?:\/\/)?(www\.)?[a-z0-9]+\.[a-z]{2,}(\/.*)?$/i;

const stepOneSchema = Joi.object({
  companyName: Joi.string()
    .trim()
    .min(1)
    .required()
    .label("Company Name")
    .error(() => {
      return {
        message: "Company Name is required.",
      };
    }),
  contactName: Joi.string()
    .trim()
    .min(1)
    .required()
    .label("Contact Name")
    .error(() => {
      return {
        message: "Contact Name is required.",
      };
    }),
  email: Joi.string()
    .regex(emailRegex)
    .email()
    .required()
    .label("Email")
    .error(() => {
      return {
        message: "Email is required and must be a valid format.",
      };
    }),
  phoneNumber: Joi.string()
    .regex(phoneNumberRegex)
    .required()
    .label("Phone Number")
    .error(() => {
      return {
        message: "Phone Number is required and must be a valid phone number.",
      };
    }),
  companyState: Joi.object({
    value: Joi.string().required(),
    label: Joi.string().required(),
  })
    .required()
    .label("Company State")
    .error(() => {
      return {
        message: "Company State is required.",
      };
    }),
  companyCity: Joi.object({
    value: Joi.number().required(),
    label: Joi.string().required(),
  })
    .required()
    .label("Company City")
    .error(() => {
      return {
        message: "Company City is required.",
      };
    }),
  status: Joi.object({
    value: Joi.number().required(),
    label: Joi.string().required(),
  })
    .required()
    .label("Status")
    .error(() => {
      return {
        message: "Status is required.",
      };
    }),
  website: Joi.string()
    .regex(websiteRegex)
    .optional()
    .label("Company Website")
    .error(() => ({ message: "Website must be a valid format if provided." })),
});

const stepTwoSchema = Joi.object({
  trades: Joi.array().min(1).required().label("Trades"),
  states: Joi.array().min(1).required().label("States"),
  cities: Joi.array().min(1).required().label("Cities"),
});

const joiErrorToMessage = (error) => {
  const errorMessages = {};
  error.details.forEach((detail) => {
    errorMessages[detail.path[0]] = detail.message;
  });
  return errorMessages;
};

export const extractErrorMessage = (error) => {
  if (error.response?.status !== 400) return "An unexpected error occurred.";

  try {
    const parsedErrors = JSON.parse(
      error.response.data.message.replace(/^Errors:\s*/, "")
    );
    for (const errors of Object.values(parsedErrors)) {
      if (Array.isArray(errors)) return errors[0];
    }
  } catch {
    return "An error occurred while parsing the error message.";
  }
  return "An error occurred. Please try again.";
};

const PartnerCreate = (props) => {
  const classes = useStyles();
  const { dispatchGlobalUi } = useContext(GlobalUiContext);
  const [step, setStep] = useState(1);
  const [vendorId, setVendorId] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [website, setWebsite] = useState("");
  const [contactName, setContactName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [status, setStatus] = useState(partnerStatus[2]);
  const [companyState, setCompanyState] = useState("");
  const [companyCity, setCompanyCity] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [tradesList, setTradesList] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [zipCodesList, setZipCodesList] = useState([]);
  const [selectedItems, setSelectedItems] = useState({
    trades: [],
    states: [],
    cities: [],
    zipCodes: [],
  });
  const [errors, setErrors] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [filterType, setFilterType] = useState("");
  const [msgRedirect, setMsgRedirect] = React.useState("");
  const [linkRedirect, setLinkRedirect] = React.useState("");
  const [labelRedirect, setLabelRedirect] = React.useState("");
  const [openRedirect, setOpenRedirect] = React.useState(false);
  const [filteredCities, setFilteredCities] = useState([]);
  const [isLoadingDialogOpen, setIsLoadingDialogOpen] = useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [ownedTypes, setOwnedTypes] = React.useState({
    woman_owned: false,
    disability_owned: false,
    veteran_owned: false,
    minority_owned: false,
  });

  const dataLists = {
    trades: tradesList,
    states: statesList,
    cities: citiesList,
    zipCodes: zipCodesList,
  };

  const fetchData = async () => {
    await fetchAndSetData(
      getTrades,
      setTradesList,
      "trades",
      "id",
      "trade_name",
      {},
      mapData
    );
    await fetchAndSetData(
      getStates,
      setStatesList,
      "states",
      "abbreviation",
      "name",
      {},
      mapData
    );
    await fetchAndSetData(
      getCities,
      setCitiesList,
      "cities",
      "id",
      "city_name",
      { state_abbreviation: "state_abbreviation" },
      mapData
    );
    await fetchAndSetData(
      getZipCodes,
      setZipCodesList,
      "zip_codes",
      "id",
      "zip",
      { city: "city.city_name", state_abbreviation: "city.state_abbreviation" },
      mapData
    );
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //Filter state and Cities for Step 1
    if (companyState) {
      const filtered = citiesList.filter(
        (city) => city.state_abbreviation === companyState.value
      );
      setFilteredCities(filtered);

      if (!filtered.some((city) => city.value === companyCity?.value)) {
        setCompanyCity(null);
      }
    } else {
      setFilteredCities([]);
    }
  }, [companyState, citiesList, companyCity]);

  useEffect(() => {
    if (selectedItems.states.length > 0) {
      const assignedCitiesToSelectedStates = filterCitiesBySelectedStates(
        selectedItems.cities,
        selectedItems.states
      );

      setSelectedItems((prevSelectedItems) => ({
        ...prevSelectedItems,
        cities: assignedCitiesToSelectedStates,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems.states]);

  useEffect(() => {
    if (selectedItems.cities.length > 0) {
      const assignedZipCodesToSelectedCities = filterZipCodesBySelectedCities(
        selectedItems.zipCodes,
        selectedItems.cities
      );

      setSelectedItems((prevSelectedItems) => ({
        ...prevSelectedItems,
        zipCodes: assignedZipCodesToSelectedCities,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems.cities]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    const setters = {
      companyName: setCompanyName,
      contactName: setContactName,
      email: setEmail,
      phoneNumber: setPhoneNumber,
      companyAddress: setCompanyAddress,
      vendorId: setVendorId,
      website: setWebsite,
    };
    if (setters[id]) {
      setters[id](value);
      validateField(id, value);
    }
  };

  const validateField = (field, value) => {
    if (field === "website" && !value) {
      setErrors((prev) => ({ ...prev, [field]: "" }));
      return;
    }

    const tempObject = { [field]: value };
    const { error } = stepOneSchema.validate(tempObject, { abortEarly: false });

    if (error) {
      const fieldError = joiErrorToMessage(error);
      setErrors((prev) => ({ ...prev, [field]: fieldError[field] || "" }));
    } else {
      setErrors((prev) => ({ ...prev, [field]: "" }));
    }
  };

  const handleOpenModal = (type) => {
    setFilterType(type);
    setSelectedItems((prevSelected) => ({
      ...prevSelected,
      [type]: dataLists[type].filter((item) =>
        prevSelected[type].some((selected) => selected.value === item.value)
      ),
    }));
    setModalOpen(true);
  };

  const handleModalChange = (selected) => {
    setSelectedItems((prevSelected) => ({
      ...prevSelected,
      [filterType]: selected,
    }));

    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (selected.length > 0) {
        delete updatedErrors[filterType];
      } else {
        updatedErrors[filterType] = `${
          filterType.charAt(0).toUpperCase() + filterType.slice(1)
        } is required`;
      }
      return updatedErrors;
    });

    setModalOpen(false);
  };

  const handleCloseModal = () => setModalOpen(false);

  const handleCloseRedirect = () => setOpenRedirect(false);

  const handleCloseErrorDialog = () => setIsErrorDialogOpen(false);

  const handleListView = () => {
    dispatchGlobalUi({
      type: "TOGGLE_SIDEDRAWER",
      isDrawerOpen: false,
    });
  };

  const handleBack = () => {
    setStep(1);
  };

  const handleNext = () => {
    const stepOneData = {
      companyName,
      contactName,
      email,
      phoneNumber,
      status: status ? { value: status.value, label: status.label } : null,
      companyState: companyState
        ? { value: companyState.value, label: companyState.label }
        : null,

      companyCity: companyCity
        ? { value: companyCity.value, label: companyCity.label }
        : null,
    };

    const { error } = stepOneSchema.validate(stepOneData, {
      abortEarly: false,
    });
    if (error) {
      setErrors(joiErrorToMessage(error));
    } else {
      setErrors({});
      setStep(2);
    }
  };

  const handleSelectStateChange = (event, newValue) => {
    setCompanyState(newValue);
    if (!newValue || !newValue.value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        companyState: "Company state is required",
      }));
    } else {
      setErrors((prevErrors) => {
        const { companyState, ...rest } = prevErrors;
        return rest;
      });
    }
  };

  const handleSelectCityChange = (event, newValue) => {
    setCompanyCity(newValue);
    if (!newValue || !newValue.value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        companyCity: "Company City is required",
      }));
    } else {
      setErrors((prevErrors) => {
        const { companyCity, ...rest } = prevErrors;
        return rest;
      });
    }
  };

  const handleStatusChange = (event, newValue) => {
    setStatus(newValue);
    if (!newValue || !newValue.value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        status: "Status is required",
      }));
    } else {
      setErrors((prevErrors) => {
        const { status, ...rest } = prevErrors;
        return rest;
      });
    }
  };

  const renderSelectedValues = (items) => {
    return items.map((item) => item.label).join(", ");
  };

  const handleOwnedTypeChange = (optionKey) => {
    setOwnedTypes((prevState) => ({
      ...prevState,
      [optionKey]: !prevState[optionKey],
    }));
  };

  const handleCreate = async () => {
    const stepTwoData = {
      trades: selectedItems.trades,
      states: selectedItems.states,
      cities: selectedItems.cities,
    };

    const { error } = stepTwoSchema.validate(stepTwoData, {
      abortEarly: false,
    });
    if (error) {
      setErrors(joiErrorToMessage(error));
    } else {
      setErrors({});
      const data = {
        company_name: companyName,
        company_website: website,
        contact_name: contactName,
        contact_email: email,
        phone_number: phoneNumber,
        state_abbreviation: companyState.value,
        city_id: companyCity.value,
        address: companyAddress,
        status: status.value,
        minority_owned: ownedTypes.minority_owned,
        woman_owned: ownedTypes.woman_owned,
        disability_owned: ownedTypes.disability_owned,
        veteran_owned: ownedTypes.veteran_owned,
        trades: selectedItems.trades.map((item) => item.value),
        states: selectedItems.states.map((item) => item.value),
        cities: selectedItems.cities.map((item) => item.value),
        zip_codes: selectedItems.zipCodes.map((item) => item.value),
        external_partner_number: vendorId,
      };
      setIsLoadingDialogOpen(true);
      try {
        const response = await createPartner(data);
        setIsLoadingDialogOpen(false);
        setOpenRedirect(true);
        setLabelRedirect("Go to Detail Page");
        setLinkRedirect(`/partner/${response.data.data.partner.id}`);
        setMsgRedirect("New partner has been created.");
      } catch (error) {
        setIsLoadingDialogOpen(false);
        const errorMessage = extractErrorMessage(error);
        setErrorMessage(errorMessage);
        setIsErrorDialogOpen(true);
        logException(error, "Error creating partner");
      }
    }
  };

  const handleSelect = async (response) => {
    const feature = response.features[0];
    if (!feature) return;

    const addressData = feature.properties;
    let city =
      addressData?.context?.locality?.name ||
      addressData?.context?.place?.name ||
      "";
    let state = addressData?.context?.region?.region_code || "";
    const zip = addressData?.context?.postcode?.name || "";

    const foundZip = zipCodesList.find((z) => z.label === zip);
    if (foundZip) {
      city = foundZip.city;
      state = foundZip.state_abbreviation;
    }

    const foundState = statesList.find(
      (st) => st.value === state.toUpperCase()
    );
    const foundCity = citiesList.find(
      (c) => c.label === city && c.state_abbreviation === state.toUpperCase()
    );
    let newStates = [...selectedItems.states];
    let newCities = [...selectedItems.cities];
    let newZipCodes = [...selectedItems.zipCodes];
    console.log(foundState, foundCity, foundZip);
    if (foundState && !selectedItems.states.some((st) => st.value === state)) {
      newStates = [...selectedItems.states, foundState];
    }
    if (
      foundCity &&
      !selectedItems.cities.some(
        (c) => c.label === city && c.state_abbreviation === state.toUpperCase()
      )
    ) {
      newCities = [...selectedItems.cities, foundCity];
    }
    if (
      foundZip &&
      !selectedItems.zipCodes.some((z) => z.label === zip && z.city === city)
    ) {
      newZipCodes = [...selectedItems.zipCodes, foundZip];
    }
    if (!foundCity && city && state) {
      const dataCity = await createCity({
        city_name: city,
        state_abbreviation: state,
      });
      const newCity = dataCity.data;
      setCitiesList([
        ...citiesList,
        {
          label: newCity.city_name,
          value: newCity.id,
          state_abbreviation: newCity.state_abbreviation,
        },
      ]);
      newCities = [
        ...selectedItems.cities,
        {
          label: newCity.city_name,
          value: newCity.id,
          state_abbreviation: newCity.state_abbreviation,
        },
      ];
    }
    if (!foundZip && city && state && zip) {
      const dataZip = await createZipCode({
        city_name: city,
        state_abbreviation: state,
        zip: zip,
      });
      const newZip = dataZip.data;
      setZipCodesList([
        ...zipCodesList,
        {
          label: newZip.zip,
          value: newZip.id,
          city,
          state_abbreviation: state,
        },
      ]);
      newZipCodes = [
        ...selectedItems.zipCodes,
        {
          label: newZip.zip,
          value: newZip.id,
          city,
          state_abbreviation: state,
        },
      ];
    }
    setSelectedItems({
      trades: selectedItems.trades,
      states: newStates,
      cities: newCities,
      zipCodes: newZipCodes,
    });
  };

  return (
    <>
      <Dialog
        open={isLoadingDialogOpen}
        aria-labelledby="loading-dialog-title"
        aria-describedby="loading-dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <DialogContent>
          <LoadingIndicator />
        </DialogContent>
      </Dialog>

      <Box className={classes.headerSection}>
        <Box className={classes.headerRow}>
          <Box className={classes.stepLabel} />
          <Box className={classes.stepLabel}>
            {step === 1 && "Step 1/2"}
            {step === 2 && "Step 2/2"}
          </Box>
        </Box>
        <Box className={classes.headerTextContainer}>
          {step === 1 && (
            <>
              <Typography variant="h6" className={classes.title}>
                General Information
              </Typography>
              <Typography variant="body2" className={classes.paragraph}>
                Tell us more about the partner you're creating.
              </Typography>
            </>
          )}
          {step === 2 && (
            <>
              <Typography variant="h6" className={classes.title}>
                Trades and Geographical Coverage
              </Typography>
              <Typography variant="body2" className={classes.paragraph}>
                Tell us more about the services offered by this partner.
              </Typography>
            </>
          )}
        </Box>
      </Box>
      <Box className={classes.contentsContainer}>
        {step === 1 && (
          <>
            <FormControl className={classes.formControl} fullWidth>
              <FormLabel htmlFor="companyName" className={classes.labelInputs}>
                Company Name*
              </FormLabel>
              <TextField
                id="companyName"
                name="companyName"
                variant="standard"
                placeholder="Write the Name"
                value={companyName}
                onChange={handleChange}
                InputProps={{ disableUnderline: true }}
                className={classes.inputField}
              />
              {errors.companyName && (
                <FormHelperText className={classes.formHelperText}>
                  {errors.companyName}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl className={classes.formControl} fullWidth>
              <FormLabel
                htmlFor="companyWebsite"
                className={classes.labelInputs}
              >
                Company Website
              </FormLabel>
              <TextField
                id="website"
                name="website"
                variant="standard"
                placeholder="Write the website"
                value={website}
                onChange={handleChange}
                InputProps={{ disableUnderline: true }}
                className={classes.inputField}
              />
              {errors.website && (
                <FormHelperText className={classes.formHelperText}>
                  {errors.website}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl className={classes.formControl} fullWidth>
              <FormLabel htmlFor="companyState" className={classes.labelInputs}>
                Company State*
              </FormLabel>
              <FormSelectAuto
                id="companyState"
                name="companyState"
                placeholder="Select State"
                options={statesList}
                value={companyState}
                handleChange={handleSelectStateChange}
                error={
                  errors.companyState
                    ? [{ key: "companyState", message: errors.companyState }]
                    : []
                }
              />
            </FormControl>
            <FormControl className={classes.formControl} fullWidth>
              <FormLabel htmlFor="companyCity" className={classes.labelInputs}>
                Company City*
              </FormLabel>
              <FormSelectAuto
                id="companyCity"
                name="companyCity"
                placeholder="Select City"
                disabled={!companyState}
                options={filteredCities}
                value={companyCity}
                handleChange={handleSelectCityChange}
                error={
                  errors.companyCity
                    ? [{ key: "companyCity", message: errors.companyCity }]
                    : []
                }
              />
            </FormControl>
            <FormControl className={classes.formControl} fullWidth>
              <FormLabel
                htmlFor="companyAddress"
                className={classes.labelInputs}
              >
                Company Address
              </FormLabel>
              <TextField
                id="companyAddress"
                name="companyAddress"
                variant="standard"
                placeholder="Write Address"
                value={companyAddress}
                onChange={handleChange}
                InputProps={{ disableUnderline: true }}
                className={classes.inputField}
              />
            </FormControl>
            <FormControl className={classes.formControl} fullWidth>
              <FormLabel htmlFor="contactName" className={classes.labelInputs}>
                Contact Name*
              </FormLabel>
              <TextField
                id="contactName"
                name="contactName"
                variant="standard"
                placeholder="Write a Name"
                value={contactName}
                onChange={handleChange}
                InputProps={{ disableUnderline: true }}
                className={classes.inputField}
              />
              {errors.contactName && (
                <FormHelperText className={classes.formHelperText}>
                  {errors.contactName}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl className={classes.formControl} fullWidth>
              <FormLabel htmlFor="contactEmail" className={classes.labelInputs}>
                Contact Email*
              </FormLabel>
              <TextField
                id="email"
                name="email"
                variant="standard"
                placeholder="Write an Email"
                value={email}
                onChange={handleChange}
                InputProps={{ disableUnderline: true }}
                className={classes.inputField}
              />
              {errors.email && (
                <FormHelperText className={classes.formHelperText}>
                  {errors.email}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl className={classes.formControl} fullWidth>
              <FormLabel htmlFor="phoneNumber" className={classes.labelInputs}>
                Phone Number*
              </FormLabel>
              <TextField
                id="phoneNumber"
                name="phoneNumber"
                variant="standard"
                placeholder="Write Number"
                value={phoneNumber}
                onChange={handleChange}
                InputProps={{ disableUnderline: true }}
                className={classes.inputField}
              />
              {errors.phoneNumber && (
                <FormHelperText className={classes.formHelperText}>
                  {errors.phoneNumber}
                </FormHelperText>
              )}
            </FormControl>
            <Box className={classes.selectContainer}>
              <FormSelectAuto
                id="status"
                name="status"
                label="Status*"
                placeholder="Select Status"
                options={partnerStatus}
                value={status}
                handleChange={handleStatusChange}
                error={
                  errors.status
                    ? [{ key: "status", message: errors.status }]
                    : []
                }
              />
            </Box>
            <Box
              className={classes.checkboxListContainer}
              style={{ marginTop: 25 }}
            >
              {ownershipTypes.map((option) => (
                <Box key={option.value} display="flex" alignItems="center">
                  <Checkbox
                    checked={ownedTypes[option.value] || false}
                    onChange={() => handleOwnedTypeChange(option.value)}
                    classes={{ root: classes.filterCheckbox }}
                  />
                  <Typography variant="body1" className={classes.checkboxLabel}>
                    {option.label}
                  </Typography>
                </Box>
              ))}
            </Box>
            <FormControl className={classes.formControl} fullWidth>
              <FormLabel htmlFor="vendorId" className={classes.labelInputs}>
                Vendor Id
              </FormLabel>
              <TextField
                id="vendorId"
                name="vendorId"
                variant="standard"
                placeholder="Write Vendor Id"
                value={vendorId}
                onChange={handleChange}
                InputProps={{ disableUnderline: true }}
                className={classes.inputField}
              />
            </FormControl>
            <Box className={classes.buttonContainer} style={{ marginTop: 30 }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleListView}
                startIcon={<ArrowBackIcon className={classes.icon} />}
                className={classes.backButton}
              >
                Go Back
              </Button>
              <Button
                variant="outlined"
                onClick={handleNext}
                style={{ minWidth: 150 }}
                className={classes.buttonPartner}
              >
                Next
              </Button>
            </Box>
          </>
        )}

        {step === 2 && (
          <>
            <Box>
              <FormControl className={classes.formControl} fullWidth>
                <FormLabel className={classes.filterLabel}>
                  Trade Offered*
                </FormLabel>
                <Box
                  onClick={() => handleOpenModal("trades")}
                  className={classes.box}
                >
                  <TextField
                    required
                    fullWidth
                    id="trades"
                    name="trades"
                    variant="standard"
                    placeholder="Select Trade"
                    value={renderSelectedValues(selectedItems.trades)}
                    InputProps={{
                      className: classes.inputHeight,
                      disableUnderline: true,
                      readOnly: true,
                      endAdornment: (
                        <IconButton>
                          <ArrowRightIcon />
                        </IconButton>
                      ),
                    }}
                    className={classes.inputFieldFilters}
                  />
                </Box>
                {errors.trades && (
                  <FormHelperText className={classes.formHelperText}>
                    {errors.trades}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
            <Box className={classes.searchBoxContainer}>
              <SearchBox
                accessToken={mapBoxKey}
                onRetrieve={handleSelect}
                options={{
                  language: "en",
                  country: "US",
                }}
              />
            </Box>
            <Box>
              <FormControl className={classes.formControl} fullWidth>
                <FormLabel
                  className={
                    selectedItems.trades.length > 0
                      ? classes.filterLabel
                      : classes.inputLabel
                  }
                >
                  States Serviced*
                </FormLabel>
                <Box
                  onClick={() =>
                    selectedItems.trades.length > 0 && handleOpenModal("states")
                  }
                  className={classes.box}
                >
                  <TextField
                    required
                    fullWidth
                    id="states"
                    name="states"
                    variant="standard"
                    placeholder="Select state"
                    value={renderSelectedValues(selectedItems.states)}
                    InputProps={{
                      className: classes.inputHeight,
                      disableUnderline: true,
                      readOnly: true,
                      endAdornment: selectedItems.trades.length > 0 && (
                        <IconButton>
                          <ArrowRightIcon />
                        </IconButton>
                      ),
                    }}
                    className={classnames(classes.inputFieldFilters, {
                      [classes.readOnlyInput]:
                        selectedItems.trades.length === 0,
                    })}
                  />
                </Box>
                {errors.states && (
                  <FormHelperText className={classes.formHelperText}>
                    {errors.states}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
            <Box>
              <FormControl className={classes.formControl} fullWidth>
                <FormLabel
                  className={
                    selectedItems.states.length > 0
                      ? classes.filterLabel
                      : classes.inputLabel
                  }
                >
                  Cities Serviced*
                </FormLabel>
                <Box
                  onClick={() =>
                    selectedItems.states.length > 0 && handleOpenModal("cities")
                  }
                  className={classes.box}
                >
                  <TextField
                    required
                    fullWidth
                    id="cities"
                    name="cities"
                    variant="standard"
                    placeholder="Select Cities "
                    value={renderSelectedValues(selectedItems.cities)}
                    InputProps={{
                      className: classes.inputHeight,
                      disableUnderline: true,
                      readOnly: true,
                      endAdornment: selectedItems.states.length > 0 && (
                        <IconButton>
                          <ArrowRightIcon />
                        </IconButton>
                      ),
                    }}
                    className={classnames(classes.inputFieldFilters, {
                      [classes.readOnlyInput]:
                        selectedItems.states.length === 0,
                    })}
                  />
                </Box>
                {errors.cities && (
                  <FormHelperText className={classes.formHelperText}>
                    {errors.cities}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
            <Box>
              <FormControl className={classes.formControl} fullWidth>
                <FormLabel
                  className={
                    selectedItems.cities.length > 0
                      ? classes.filterLabel
                      : classes.inputLabel
                  }
                >
                  Zip Codes
                </FormLabel>
                <Box
                  onClick={() =>
                    selectedItems.cities.length > 0 &&
                    handleOpenModal("zipCodes")
                  }
                  className={classes.box}
                >
                  <TextField
                    fullWidth
                    id="zipCodes"
                    name="zipCodes"
                    variant="standard"
                    placeholder="Select Zip Codes"
                    value={renderSelectedValues(selectedItems.zipCodes)}
                    InputProps={{
                      className: classes.inputHeight,
                      disableUnderline: true,
                      readOnly: true,
                      endAdornment: selectedItems.cities.length > 0 && (
                        <IconButton>
                          <ArrowRightIcon />
                        </IconButton>
                      ),
                    }}
                    className={classnames(classes.inputFieldFilters, {
                      [classes.readOnlyInput]:
                        selectedItems.cities.length === 0 &&
                        selectedItems.zipCodes.length === 0,
                    })}
                  />
                </Box>
              </FormControl>
            </Box>
            <Box className={classes.buttonContainer}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleBack}
                startIcon={<ArrowBackIcon className={classes.icon} />}
                className={classes.backButton}
              >
                Back
              </Button>
              <Button
                variant="contained"
                onClick={handleCreate}
                style={{ minWidth: 150 }}
                className={classes.buttonPartner}
              >
                Create Partner
              </Button>
            </Box>
          </>
        )}
      </Box>
      <FilterModal
        isOpen={modalOpen}
        onCloseModal={handleCloseModal}
        availableStates={statesList}
        filterType={filterType}
        optionsList={dataLists[filterType] || []}
        currentSelections={selectedItems}
        onFilterChange={handleModalChange}
      />
      <RedirectDialog
        title="Success"
        open={openRedirect}
        message={msgRedirect}
        redirect={linkRedirect}
        redirectLabel={labelRedirect}
        hasClose={false}
        handleClose={handleCloseRedirect}
      />
      <ErrorDialog
        open={isErrorDialogOpen}
        title="Error!"
        message={errorMessage}
        handleClose={handleCloseErrorDialog}
      />
    </>
  );
};

export default PartnerCreate;
